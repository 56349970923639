import React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
import {
  Hero,
  Presentation,
  Steps,
  Technologies,
  DrupalAssociation,
  Services,
  Temoignages,
} from "components/paragraphs"

const HomePage = ({ data }) => {
  const {
    nodeHomePage: {
      relationships: {
        field_hero,
        field_service,
        field_presentation,
        field_steps,
        field_technologies,
        field_feature,
        field_temoignages,
        field_drupal_association,
      },
    },
  } = data

  const metadata = {
    title: `${field_hero?.field_title} ${field_hero?.field_subtitle}`,
    description: field_hero?.field_description?.processed,
    image: "/og-image.png",
  }

  return (
    <Layout>
      <SEO {...metadata} />
      <Hero {...field_hero} />
      <Services field_service={field_service} />
      <Presentation {...field_presentation} />
      <Steps {...field_steps} />
      <Technologies {...field_technologies} />
      <Temoignages field_temoignages={field_temoignages}>
        <Presentation {...field_feature} />
      </Temoignages>
      <DrupalAssociation {...field_drupal_association} />
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query HomePageQuery($locale: String = "fr") {
    nodeHomePage(
      langcode: { eq: $locale }
      path: { alias: { eq: "/homepage" } }
    ) {
      relationships {
        field_hero {
          ...HeroParagraph
        }
        field_service {
          ...ServiceParagraph
        }
        field_presentation {
          ...PresentationParagraph
        }
        field_steps {
          ...StepsParagraph
        }
        field_technologies {
          ...TechnologyParagraph
        }
        field_feature {
          ...PresentationParagraph
        }
        field_temoignages {
          ...TemoignageParagraph
        }
        field_drupal_association {
          ...DrupalAssociationParagraph
        }
      }
    }
  }
`
